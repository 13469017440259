import React from "react";

import { Flex, TertiaryButton } from "flicket-ui";
import { Icon } from "~components/common/Icon";

type SocialButtonProps = {
  handleGoogleClick: () => void;
  handleFacebookClick: () => void;
  isGoogleLoading?: boolean;
  isFacebookLoading?: boolean;
};

export const SocialButtons = ({
  handleGoogleClick,
  handleFacebookClick,
  isFacebookLoading,
  isGoogleLoading,
}: SocialButtonProps) => {
  return (
    <Flex mt={3}>
      <TertiaryButton
        maxH="48px"
        flex="1"
        fontSize={4}
        color="N600"
        borderColor="N600"
        mr={2}
        px={0}
        py="6/4"
        onClick={handleFacebookClick}
        isLoading={isFacebookLoading}
      >
        <Icon icon="facebook" fontSize={6} mr="1/2" />
        Facebook
      </TertiaryButton>
      <TertiaryButton
        maxH="48px"
        flex="1"
        fontSize={4}
        color="N600"
        borderColor="N600"
        px={0}
        py="6/4"
        onClick={handleGoogleClick}
        isLoading={isGoogleLoading}
      >
        <Icon icon="google" fontSize={6} mr="1/4" />
        Google
      </TertiaryButton>
    </Flex>
  );
};
